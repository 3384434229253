<template>
	<div class="layout-topbar" style="justify-content: space-between;">
		<button class="p-link layout-menu-button layout-topbar-button" style="margin-left: 10px;" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
<!-- 		<router-link style="float: right;" to="/xmlvisualization" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>空间数字图谱构建系统</span>
		</router-link> -->
		<h5 style="margin-top: auto;">空间数字图谱构建系统</h5>

		<!-- <button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="toggleSetting($event)">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
		</ul> -->
	</div>
</template>

<script>
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		toggleSetting() {
			// console.log(event);
			this.$emit('toggleSetting');
		},
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>