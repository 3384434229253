<template>
	<Toast></Toast>
	<div class="middle" :style="backgroundDiv">
		<div style='background-color: rgba(255, 255, 255, 0.6);margin: 30px;'
			class="surface-card p-6 shadow-2 border-round w-full lg:w-4">
			<div class="text-center mb-5">
				<img src="images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3">
				<div class="text-900 text-3xl font-medium mb-3">空间数字图谱构建系统</div>
				<span class="text-600 font-medium line-height-3">注册</span>
				<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
					title='Email:990527197@qq.com'>请发送邮件申请</a>
			</div>

			<div>
				<label for="email1" class="block text-900 font-medium mb-2">邮箱</label>
				<InputText id="email1" type="text" v-model='email' class="w-full mb-3" />

				<label for="password1" class="block text-900 font-medium mb-2">密码</label>
				<InputText id="password1" v-on:keyup.enter="login" v-model='password' type="text" class="w-full mb-3" />

				<div class="flex align-items-center justify-content-between mb-6">
					<div class="flex align-items-center">
						<Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
						<label for="rememberme1">自动登录</label>
					</div>
					<a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
						title='请联系990527197@qq.com'>忘记密码?</a>
				</div>

				<Button @click="login" label="立即登录" icon="pi pi-user" class="w-full"></Button>
			</div>
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		data() {
			return {
				checked: true,
				email: '',
				password: '',
				has_cookie: false,
				backgroundDiv: {
					// backgroundImage: 'url(https://fireflycos.libertynlp.com/kg-static/kg-backimg.jpg)',
					backgroundImage: 'url(https://fireflycos.libertynlp.com/kg-static/qiuye-2.jpg)',
				}
			}
		},
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			this.readUserInfo();
			// this.login();
		},
		methods: {
			readUserInfo() {
				if (localStorage.getItem('kg_user_info') !== null) {
					var kg_user_info = JSON.parse(localStorage.getItem("kg_user_info"));
					this.email = kg_user_info.email;
					this.password = kg_user_info.password;
				}
			},
			login() {
				
				// this.$router.push({
				// 	path: '/',
				// })
				this.tryEmailLogin(this.email, this.password);
			},
			async tryEmailLogin(user_email, user_password) {
				this.$appState.processing = true;
				var data = new URLSearchParams();
				data.append("user_email", user_email);
				data.append("user_password", user_password);
				var url = '/member/email-login';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				// console.log('resp', resp);
				if (resp.code == 200) {
					//关掉登录页面
					var user_info = {
						'email': this.email,
						'password': this.password,
					};
					//请求头
					localStorage.setItem('Authorization', JSON.stringify(user_info));
					
					if (this.checked == true) {
						localStorage.setItem('kg_user_info', JSON.stringify(user_info));
					} else {
						var null_user_info = {
							'email': '',
							'password': '',
						};
						localStorage.setItem('kg_user_info', JSON.stringify(null_user_info));
					}
					

					this.$appState.is_login = true;
					this.$emit('flushLoginStatus');
					this.$toast.add({
						severity: 'success',
						summary: '登录成功',
						detail: 'Enjoy your study!',
						life: 1000
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: '失败',
						detail: resp.msg,
						life: 4000
					});
				}
				this.$appState.processing = false;
			}
		}

	}
</script>

<style scoped>
	.middle {
		height: 100%;
		position: fixed;
		width: 100%;
		background: no-repeat center top;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-container {
		display: flex;
		width: 340px;
		flex-direction: column;
		padding: 20px;
		border-radius: 10px;
		/* background-color:#55585a+7a; */
		background-color: rgba(255, 255, 255, 0.4);
		justify-content: center;
	}

	.login-lable {
		color: #000000;
		opacity: 0.6;
		font-weight: 700;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}
</style>
